<template>
    <label>
        <div :class="['form-label l-inline', { required }]">
            {{ label }}

            <icon-button
                v-if="info"
                class="info-button"
                @click="showInfoBox = !showInfoBox"
            >
                <info-icon :width="13" :height="13" />
            </icon-button>
        </div>

        <info-box v-if="showInfoBox" class="info-box">{{ info }}</info-box>

        <div v-if="showRequiredError" class="form-error">
            {{ $t('requiredError') }}
        </div>

        <div v-for="(error, index2) in errors" :key="index2" class="form-error">
            {{ error }}
        </div>

        <slot name="error"></slot>

        <input
            v-if="inputTag == 'input'"
            v-model="inputValue"
            class="l-full-width"
            :class="{ 'password-input': type === 'password' }"
            :type="typeInternal"
            v-bind="$attrs"
            @blur="$emit('blur')"
            @input="$emit('input', inputValue)"
        />

        <textarea
            v-else-if="inputTag === 'textarea'"
            v-model="inputValue"
            class="l-full-width"
            :rows="rows"
            :type="type"
            v-bind="$attrs"
            @blur="$emit('blur')"
            @input="$emit('input', inputValue)"
        />

        <a
            v-if="inputTag === 'input' && type === 'password'"
            class="password-toggle"
            @click="switchPasswordType"
        >
            <eye-icon
                v-if="typeInternal === 'password'"
                width="18"
                height="18"
            />

            <eye-crossed-icon v-else width="18" height="18" />
        </a>
    </label>
</template>

<script>
import EyeIcon from './icons/EyeIcon'
import EyeCrossedIcon from './icons/EyeCrossedIcon'
import InfoIcon from './icons/InfoIcon'
import InfoBox from './InfoBox'
import IconButton from './IconButton'

export default {
    name: 'FormInput',
    components: {
        EyeIcon,
        EyeCrossedIcon,
        InfoIcon,
        InfoBox,
        IconButton,
    },
    // Disable automatic attribute inheritance, so that $attrs are
    // passed to the <input>, even if it's not the root element.
    // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
    inheritAttrs: false,
    props: {
        required: {
            type: Boolean,
            default: false,
        },
        showRequiredError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: 'text',
            // Only allow types that essentially just render text boxes.
            validator(value) {
                return [
                    'email',
                    'number',
                    'password',
                    'search',
                    'tel',
                    'text',
                    'url',
                ].includes(value)
            },
        },
        inputTag: {
            type: String,
            default: 'input',
            validator(value) {
                return ['input', 'textarea'].includes(value)
            },
        },
        info: {
            type: String,
            default: null,
        },
        value: {
            type: [String, Number],
            default: '',
        },
        rows: {
            type: Number,
            default: 4,
        },
    },
    data() {
        return {
            inputValue: this.value,
            showInfoBox: false,
            typeInternal: this.type,
        }
    },
    watch: {
        value(value) {
            this.inputValue = value
        },
    },
    methods: {
        switchPasswordType() {
            this.typeInternal =
                this.typeInternal === 'text' ? 'password' : 'text'
        },
    },
}
</script>

<i18n>
{
    "en": {
        "requiredError": "this field can't be empty."
    },
    "de": {
        "requiredError": "Dieses Feld darf nicht leer sein."
    },
    "it": {
        "requiredError": "Questo campo non può essere vuoto."
    }
    
}
</i18n>

<style lang="scss" scoped>
.password-input {
    padding-right: 2rem;
}

.password-toggle {
    position: relative;
    float: right;
    margin-top: -26px;
    margin-right: 8px;
    border: none;
    background: none;
    outline: none;
}
</style>
